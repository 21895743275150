import i18n from '@/i18n';

const configFormShare = () => {
    return {
        configFieldTitleBlockShare: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingShare.titleBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldUrlShare: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingShare.url'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldShareText: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingShare.shareText'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldHashtag: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingShare.hashtag'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
};

export { configFormShare };
