<template>
    <div>
        <div class="col-sm-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingShare.titleBlock') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_share.title_block"
                            :config.sync="configForm.configFieldTitleBlockShare"
                            @blur="handleValidateField(configForm.configFieldTitleBlockShare)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.background') }}</label>
                    <div class="col-sm-9">
                        <div>
                            <UploadImageCommon
                                @dataFile="handleFileBackgroundImgShare"
                                title="ファイルアップロード"
                                :id="`BackgroundImgShare`"
                                :config="{
                                    maxSize: 2048
                                }"
                            />
                            <div v-if="dataForm.block_share.background_image?.path" style="width: 200px; height: 200px" class="position-relative">
                                <b-img
                                    class=""
                                    thumbnail
                                    fluid
                                    :src="dataForm.block_share.background_image?.path"
                                    :alt="dataForm.block_share.background_image.origin_name"
                                    style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                ></b-img>
                                <span
                                    style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                    @click="handleDeleteTitleImage"
                                    ><i class="ri-close-line" style="vertical-align: bottom"></i
                                ></span>
                            </div>
                        </div>
                        <div class="my-3">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="dataForm.block_share.background_color"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                            />
                        </div>
                        <div>
                            <InputCheckBox
                                :model.sync="dataForm.block_share.is_repeat"
                                :id="`check-repeat-background-share`"
                                :value="dataForm.block_share.is_repeat"
                                :label="`repeat`"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.url') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_share.url"
                            :config.sync="configForm.configFieldUrlShare"
                            :disabled="true"
                            @blur="handleValidateField(configForm.configFieldUrlShare)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.shareText') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_share.share_text"
                            :config.sync="configForm.configFieldShareText"
                            @blur="handleValidateField(configForm.configFieldShareText)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.hashtag') }} </label>
                    <div class="col-sm-9">
                        <KeyWordTag2
                            :id="`hashtag-block_share`"
                            checkPre="hash"
                            :model.sync="dataForm.block_share.hashtag"
                            :config.sync="configForm.configFieldHashtag"
                            @blur="handleValidateField(configForm.configFieldHashtag)"
                        >
                            <span v-if="configForm.configFieldHashtag.error">
                                {{ configForm.configFieldHashtag.errorCustomMess }}
                            </span>
                        </KeyWordTag2>
                    </div>
                </div>
                <div v-for="social in SOCIAL_MEDIA_SHARE" :key="social.id" class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ social.name }}</label>
                    <div class="col-sm-9">
                        <InputCheckBox
                            :model.sync="dataForm.block_share.platform[social.key]"
                            :id="`check-social-${social.id}`"
                            :value="dataForm.block_share.platform[social.key]"
                            :label="``"
                            @change="handleChangeStatusShare(dataForm.block_share.platform)"
                        />
                    </div>
                </div>
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { handleDownFile } from '@/utils/format';
import { validateField } from '@/utils/validate';
import { SOCIAL_MEDIA_SHARE } from '../../constants';

import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import KeyWordTag2 from '@/components/KeywordTag/main2.vue';
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingShare',
    components: {
        InputText,
        InputCheckBox,
        UploadImageCommon,
        KeyWordTag2,
        ExportLandingPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            isVisibleEditor: false,
            isPreview: false,
            objEditor: null,
            configEditor: {
                height: 600
            },
            SOCIAL_MEDIA_SHARE: SOCIAL_MEDIA_SHARE
        };
    },
    methods: {
        ...handleDownFile,

        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileBackgroundImgShare(file) {
            this.dataForm.block_share.background_image = file;
        },

        handleDeleteTitleImage() {
            this.dataForm.block_share.background_image = null;
            // this.handleShotCodeStyle();
        },

        handleSelectTypeContact() {
            // this.handleShotCodeStyle();
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        },

        handleChangeStatusShare(objPlat) {
            SOCIAL_MEDIA_SHARE.forEach((itemSocial) => {
                itemSocial.active = objPlat[itemSocial.key] ?? false;
            });
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
