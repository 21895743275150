<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.nameCampaign') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.campaign?.name }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.idLP') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.code ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.nameLandingPage') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.name }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.numberCP') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.number_cp ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.businessAssistant') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.sale_staff?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.picInstructions') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm.person_in_charge?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.ZACID') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.zac_id ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.SFID') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.sfid ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.campaignEntityId') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign_entity_id ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.campaignStartDate') }} </label>
                    <div class="col-sm-9">
                        {{ formatDateCommon(`${dataForm.campaign_start_date} ${dataForm.campaign_start_time}`, 'fullDateTimeJP') }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.campaignEndDate') }} </label>
                    <div class="col-sm-9">
                        {{ formatDateCommon(`${dataForm.campaign_end_date} ${dataForm.campaign_end_time}`, 'fullDateTimeJP') }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.landingPageStartDate') }} </label>
                    <div class="col-sm-9">
                        {{ formatDateCommon(`${dataForm.lp_start_date} ${dataForm.lp_start_time}`, 'fullDateTimeJP') }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.landingPageEndDate') }} </label>
                    <div class="col-sm-9">
                        {{ formatDateCommon(`${dataForm.lp_end_date} ${dataForm.lp_end_time}`, 'fullDateTimeJP') }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.status') }} </label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            {{ handleStatus(dataForm.status) }}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.meta') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.keyword') }} </label>
                            <div class="col-sm-9">
                                {{ handleJoinText(dataForm.keywords) }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.description') }} </label>
                            <div class="col-sm-9">
                                {{ dataForm.description }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.analytics') }} </label>
                    <div class="col-sm-9" v-html="textLineBreaks(dataForm.analytics)"></div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingBasic.urlPublic') }}
                        <div class="mt-1">
                            <span>{{ $t('landingPage.settingBasic.desUrlPublic') }}</span>
                        </div>
                    </label>
                    <div class="col-sm-9">
                        {{ dataForm.url_public }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingBasic.ogImage') }} </label>
                    <div class="col-sm-9">
                        <div v-if="dataForm.og_image?.origin_name" class="d-flex align-items-center my-2">
                            <a
                                target="_blank"
                                :href="dataForm.og_image?.path"
                                @click.prevent="downLoadFile(dataForm.og_image?.path, dataForm.og_image?.origin_name)"
                                >{{ dataForm.og_image?.origin_name }}</a
                            >
                        </div>
                        <b-img
                            v-if="dataForm.og_image?.path && arrExtension.includes(dataForm.og_image.extension)"
                            class=""
                            thumbnail
                            fluid
                            :src="dataForm.og_image?.path"
                            :alt="dataForm.og_image.origin_name"
                            rounded
                            style="width: 150px; height: 150px; object-fit: cover; object-position: center"
                        ></b-img>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { handleDownFile, formatDateCommon, renderLineBreaks, IMAGE_EXTENSIONS } from '@/utils/format';
import { STATUS_SETTING_BASIC } from '../../../constants';

export default {
    name: 'SettingBasicLP',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    computed: {},
    data() {
        return {
            isLoading: false,
            arrExtension: IMAGE_EXTENSIONS
        };
    },
    methods: {
        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        handleStatus(status) {
            if (!status) return '';
            return STATUS_SETTING_BASIC.find((s) => s.id == status)?.value ?? '';
        },

        handleJoinText(arrText) {
            if (!arrText || arrText?.length <= 0) return '';
            return arrText.map((text) => text).join('、');
        },

        textLineBreaks(text) {
            return renderLineBreaks(text);
        }
    }
};
</script>

<style lang="scss" scoped></style>
