<script>
import { masterMethods, partnersMethods, checkPermission, formattedPostalCode } from '@/state/helpers';
import { renderLineBreaks } from '@/utils/format';
import { campaignStatus, arrayTypeFixed } from '@/config/var-common';
import { showMessage } from '@/utils/messages';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

export default {
    page: {
        title: '外部パートナー詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                type: {
                    id: ''
                },
                note: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                keyword: ''
            },
            listDataMasterTeam: [],
            listPartnerCP: [],
            listPartnerSupport: [],
            listDataDepartment: arrayTypeFixed,
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
            this.getListPartnersSupport(this.$route.query.id);
        }
    },
    methods: {
        formattedPostalCode,
        ...masterMethods,
        ...partnersMethods,
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        textLineBreaks(text) {
            return renderLineBreaks(text);
        },
        renderSttText(numberStt) {
            const result = campaignStatus.find((item) => item.id === numberStt);
            return result ? result.text : '';
        },
        getDetail(id) {
            this.loading = true;
            this.detailPartners(id).then((data) => {
                this.form = data;
                this.listPartnerCP = data.campaigns;
                this.loading = false;
                if (data?.types?.length > 0) {
                    const getNameTypes = this.listDataDepartment.filter((item) => data.types.find((element) => element == item.id));
                    this.form.types = getNameTypes.map((item) => item.value).join(', ');
                }
            });
        },
        goToEdit(path, id, partner_id) {
            this.$router.push({
                path: path,
                query: { id: id, partner_id: partner_id }
            });
        },
        getListPartnersSupport(id) {
            this.loading = true;
            this.listPartnersSupport(id).then((data) => {
                this.listPartnerSupport = data.data;
                this.loading = false;
            });
        },
        formatDateToChinese(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
            const date = new Date(time);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            return `${year} 年 ${month} 月 ${day} 日`;
        },
        deleteObject(data) {
            let text = 'この外部パートナーを削除してもよろしいですか。';
            if (this.form.campaigns && this.form.campaigns.length > 0) {
                text = '紐付けられているキャンペーンがあるため削除できません。';
                Swal.fire({
                    title: text,
                    icon: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#b5b8bf',
                    cancelButtonText: 'キャンセル'
                });
                return;
            }
            Swal.fire({
                title: text,
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deletePartners(data).then((data) => {
                        console.log(data);
                        if (data.code == 200) {
                            this.$router.push({ path: '/partners' }).then(() => {
                                showMessage(data.code, this.$bvToast, '外部パートナー担当者が削除されました。');
                                this.getList();
                            });
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    });
                }
            });
        },

        renderNameTool(tool) {
            if (tool) {
                return tool.map((item) => item.tool).join(', ');
            }
            return '';
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation">
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.date') }}</label>
                                        <div class="col-sm-9">
                                            {{ formatDateToChinese(form.created_at) }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.name') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.name }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.name_kana') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.kana_name }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">法人番号</label>
                                        <div class="col-sm-9">
                                            {{ form.corp_number }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.phone') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.phone_number }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.address') }}</label>
                                        <div class="col-sm-9">
                                            〒{{ formattedPostalCode(form.postal_code) }}
                                            {{ form.province }}
                                            {{ form.address_1 }}
                                            {{ form.address_2 }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.description') }}</label>
                                        <div class="col-sm-9">
                                            <p v-html="textLineBreaks(form.note)"></p>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.email') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.email }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="mailboxAddress">{{ $t('pagePartner.mailboxAddress') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.mailbox_address }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="directAddress">{{ $t('pagePartner.directAddress') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.direct_address }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.tool') }}</label>
                                        <div class="col-sm-9">
                                            {{ renderNameTool(form.tools) }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.url') }}</label>
                                        <div class="col-sm-9">
                                            <a :href="form.url" target="_blank">{{ form.url }}</a>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pagePartner.searchKeyword') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.keywords?.join(', ') }}
                                        </div>
                                    </div>

                                    <hr />
                                    <h5><b>キャンペーン一覧</b></h5>

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered table-box">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('pagePartner.no') }}</th>
                                                    <th>CP名</th>
                                                    <th>進捗ステータス</th>
                                                    <th>事務局開始日</th>
                                                    <th>CP終了日</th>
                                                    <th>事務局終了日</th>
                                                    <th>担当</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="listPartnerCP.length > 0">
                                                <template>
                                                    <tr v-for="(item, k) in listPartnerCP" :key="'c' + item.id">
                                                        <td>{{ k + 1 }}</td>

                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.name }}<br />
                                                                {{ item.name_kana ? item.name_kana : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ renderSttText(item.progress_status) }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.secretariat_start_date ? item.secretariat_start_date : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.campaign_end_date ? item.campaign_end_date : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.secretariat_end_date ? item.secretariat_end_date : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.person_in_charge ? item.person_in_charge.name : '' }}
                                                            </div>
                                                        </td>

                                                        <td align="center">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-primary"
                                                                v-if="checkPermiss('partner.edit') || checkPermiss('partner.register')"
                                                                @click="goToEdit('/campaign/form/view', item.id)"
                                                            >
                                                                {{ $t('btn.detail') }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.noData') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>

                                    <br />

                                    <h5><b>担当者一覧</b></h5>
                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered table-box">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('pagePartner.manager') }}</th>
                                                    <th>{{ $t('pagePartner.personCharge') }}</th>
                                                    <th>{{ $t('pagePartner.department') }}</th>
                                                    <th>{{ $t('pagePartner.email') }}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="listPartnerSupport.length > 0">
                                                <template>
                                                    <tr v-for="item in listPartnerSupport" :key="'s' + item.id">
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.name }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.kana_name ? item.kana_name : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.partner_pic_detail ? item.partner_pic_detail.department : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.email ? item.email : '' }}
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-info border-right-1"
                                                                v-if="checkPermiss('partner.edit') || checkPermiss('partner.register')"
                                                                @click="goToEdit('/partner/support/view', item.id, $route.query.id)"
                                                                >{{ $t('btn.detail') }}</a
                                                            >
                                                            <a
                                                                v-if="checkPermiss('partner.edit') || checkPermiss('partner.register')"
                                                                href="javascript:void(0)"
                                                                class="text-success"
                                                                @click="goToEdit('/partner/form/support', item.id, $route.query.id)"
                                                                >{{ $t('btn.edit') }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.noData') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center mt-4">
                                        <button
                                            v-if="checkPermiss('partner.edit') || checkPermiss('partner.register')"
                                            @click="goToEdit('/partner/form/support', '', $route.query.id)"
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            {{ $t('btn.registerPersonInCharge') }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/partners'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermiss('partner.edit')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/partner/form', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('partner.delete')"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
