<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.nameCampaign') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.firstView') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.copy') }} </label>
                            <div class="col-sm-9">
                                {{ dataForm.first_view.copy }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.subCopy') }} </label>
                            <div class="col-sm-9">
                                {{ dataForm.first_view.sub_copy }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.subCopy2') }} </label>
                            <div class="col-sm-9">
                                {{ dataForm.first_view.sub_copy_2 }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.applicationPeriod') }} </label>
                    <div class="col-sm-9">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.campaignStartDate') }}
                            </label>
                            <div class="col-sm-9">
                                {{
                                    formatDateCommon(
                                        `${dataForm.application_period.campaign_start_date} ${dataForm.application_period.campaign_start_time}`,
                                        'fullDateTimeJP'
                                    )
                                }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.campaignEndDate') }}
                            </label>
                            <div class="col-sm-9">
                                {{
                                    formatDateCommon(
                                        `${dataForm.application_period.campaign_end_date} ${dataForm.application_period.campaign_end_time}`,
                                        'fullDateTimeJP'
                                    )
                                }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.applicationDeadline') }}
                            </label>
                            <div class="col-sm-9">
                                {{ dataForm.application_period.application_deadline }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.overview') }} </label>
                            <div class="col-sm-9" v-html="textLineBreaks(dataForm.application_period.overview)"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { renderLineBreaks, formatDateCommon } from '@/utils/format';

export default {
    name: 'SettingFirstViewAndTimeApplication',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {};
    },
    methods: {
        textLineBreaks(text) {
            return renderLineBreaks(text);
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        }
    }
};
</script>

<style lang="scss" scoped></style>
