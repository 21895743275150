<template>
    <div>
        <div class="col-sm-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingApplicationRule.titleBlock') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_application_rule.title_block"
                            :config.sync="configForm.configFieldTitle"
                            @blur="handleValidateField(configForm.configFieldTitle)"
                            @change="handleShotCodeStyle"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationRule.background') }}</label>
                    <div class="col-sm-9">
                        <div>
                            <UploadImageCommon
                                @dataFile="handleFileBackgroundImgLP"
                                title="ファイルアップロード"
                                :id="`BackgroundImgLPApplicationRule`"
                                :config="{
                                    maxSize: 2048
                                }"
                            />
                            <div
                                v-if="dataForm.block_application_rule.background_image?.path"
                                style="width: 200px; height: 200px"
                                class="position-relative"
                            >
                                <b-img
                                    class=""
                                    thumbnail
                                    fluid
                                    :src="dataForm.block_application_rule.background_image?.path"
                                    :alt="dataForm.block_application_rule.background_image.origin_name"
                                    style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                ></b-img>
                                <span
                                    style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                    @click="handleDeleteTitleImage"
                                    ><i class="ri-close-line" style="vertical-align: bottom"></i
                                ></span>
                            </div>
                        </div>
                        <div class="my-3">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="dataForm.block_application_rule.background_color"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                        <div>
                            <InputCheckBox
                                :model.sync="dataForm.block_application_rule.is_repeat"
                                :id="`check-repeat-background-app_rule`"
                                :value="dataForm.block_application_rule.is_repeat"
                                :label="`repeat`"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.style') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-application-application_rule-style`"
                            :value.sync="dataForm.block_application_rule.style"
                            :options="LIST_STYLE_APPLICATION_RULE"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                            @select="handleShotCodeStyle('select')"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationRule.detail') }} </label>
                    <div class="col-sm-9">
                        <Editor
                            v-if="isVisibleEditor"
                            :id="`editorStyleApplicationRule`"
                            :isBackgroundImageEditor="false"
                            :model.sync="dataForm.block_application_rule.html_content"
                            :modelEvents="`change keydown blur focus paste`"
                            :configInit="configEditor"
                            @init="onEditorInit"
                        />
                    </div>
                </div>
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_APPLICATION_RULE } from '../../styles';
import { tranferShortCode } from '@/utils/format';

import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'SettingApplicationRule',
    components: {
        InputText,
        UploadImageCommon,
        InputCheckBox,
        Editor,
        ExportLandingPage,
        Multiselect
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            objEditor: null,
            isVisibleEditor: false,
            isPreview: false,
            configEditor: {
                height: 600,
                content_style: ''
            },
            LIST_STYLE_APPLICATION_RULE: LIST_STYLE_APPLICATION_RULE
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.block_application_rule.background_image = file;
            this.handleShotCodeStyle();
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },
        handleDeleteTitleImage() {
            this.dataForm.block_application_rule.background_image = null;
            this.handleShotCodeStyle();
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        },

        handleShotCodeStyle(type = null) {
            let { background_image, background_color, is_repeat, style } = this.dataForm.block_application_rule;
            if (!style) return;
            let addProper = '';
            if (type === 'select') {
                this.dataForm.block_application_rule.html_content = '';
                this.objEditor.setContent('');
                this.updateEditorStyle(this.objEditor, style?.cssStyle);
                this.configEditor.content_style = style?.cssStyle;
            }
            let { htmlTitle, contents } = this.dataForm.block_application_rule.style;
            if (!this.dataForm.block_application_rule?.html_content) {
                addProper = `<div class="block__application--rule" style="background-color: ${background_color ?? ''};background-image: url(${background_image?.path ?? ''});background-repeat: ${is_repeat ? 'repeat' : 'no-repeat'};min-height: 300px;overflow: hidden;background-blend-mode: multiply;">
                        <div>
                            ${this.hashShotCodeTitle(htmlTitle)}
                            ${contents}
                        </div>
                    </div>`;
            } else {
                const div = document.createElement('div');
                div.innerHTML = this.dataForm.block_application_rule?.html_content;
                const sourceBgrElement = div.querySelector('.content-rule');
                addProper = `<div class="block__application--rule" style="background-color: ${background_color ?? ''};background-image: url(${background_image?.path ?? ''});background-repeat: ${is_repeat ? 'repeat' : 'no-repeat'};min-height: 300px;overflow: hidden;background-blend-mode: multiply;">
                        <div>
                            ${this.hashShotCodeTitle(htmlTitle)}
                            ${sourceBgrElement.outerHTML}
                        </div>
                    </div>`;
            }
            this.objEditor.setContent(addProper ?? '');
            this.dataForm.block_application_rule.html_content = addProper;
        },

        updateEditorStyle(editor, newCss) {
            const head = editor.contentDocument.head || editor.getDoc().head;
            let styleTag = head.querySelector('#dynamic-editor-style');
            if (!styleTag) {
                styleTag = document.createElement('style');
                styleTag.id = 'dynamic-editor-style';
                head.appendChild(styleTag);
            }
            styleTag.innerHTML = newCss;
        },

        hashShotCodeTitle(htmlTitle) {
            const replacements1 = {
                titleBlock: this.dataForm.block_application_rule.title_block ?? ''
            };
            return tranferShortCode(htmlTitle, replacements1);
        }
    },
    watch: {},
    mounted() {
        setTimeout(() => {
            let { style } = this.dataForm.block_application_rule;
            this.isVisibleEditor = true;
            this.configEditor.content_style = style?.cssStyle;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
