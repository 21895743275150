<template>
    <div>
        <div class="col-sm-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingReceiptPhotography.title') }} </label>
                    <div class="col-sm-9">
                        <Editor
                            v-if="isVisibleEditor"
                            :id="`editorStyleReceipt`"
                            :isBackgroundImageEditor="false"
                            :model.sync="dataForm.block_receipt_photography_tutorial.html_content"
                            :modelEvents="`change keydown blur focus paste`"
                            :configInit="configEditor"
                            @init="onEditorInit"
                        />
                    </div>
                </div>
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />
        </div>
    </div>
</template>

<script>
import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingReceiptPhotography',
    components: {
        Editor,
        ExportLandingPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            isVisibleEditor: false,
            isPreview: false,
            objEditor: null,
            configEditor: {
                height: 600
            }
        };
    },
    methods: {
        onEditorInit(editor) {
            this.objEditor = editor;
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
