<template>
    <div>
        <div class="col-sm-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingContact.titleBlock') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_contact.title_block"
                            :config.sync="configForm.configFieldTitleBlockContact"
                            @blur="handleValidateField(configForm.configFieldTitleBlockContact)"
                            @change="handleShotCodeStyle"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.background') }}</label>
                    <div class="col-sm-9">
                        <div>
                            <UploadImageCommon
                                @dataFile="handleFileBackgroundImgContact"
                                title="ファイルアップロード"
                                :id="`BackgroundImgContact`"
                                :config="{
                                    maxSize: 2048
                                }"
                            />
                            <div v-if="dataForm.block_contact.background_image?.path" style="width: 200px; height: 200px" class="position-relative">
                                <b-img
                                    class=""
                                    thumbnail
                                    fluid
                                    :src="dataForm.block_contact.background_image?.path"
                                    :alt="dataForm.block_contact.background_image.origin_name"
                                    style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                ></b-img>
                                <span
                                    style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                    @click="handleDeleteTitleImage"
                                    ><i class="ri-close-line" style="vertical-align: bottom"></i
                                ></span>
                            </div>
                        </div>
                        <div class="my-3">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="dataForm.block_contact.background_color"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                        <div>
                            <InputCheckBox
                                :model.sync="dataForm.block_contact.is_repeat"
                                :id="`check-repeat-background-contact`"
                                :value="dataForm.block_contact.is_repeat"
                                :label="`repeat`"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.style') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-contact_setting-style`"
                            :value.sync="dataForm.block_contact.style"
                            :options="listStyleContact"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                            @select="handleShotCodeStyle('select')"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.contactOfficeName') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_contact.contact_secretariat_name"
                            :config.sync="configForm.configFieldContactOfficeName"
                            @blur="handleValidateField(configForm.configFieldContactOfficeName)"
                            @change="handleShotCodeStyle"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.tel') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_contact.tel"
                            :config.sync="configForm.configFieldTel"
                            @blur="handleValidateField(configForm.configFieldTel)"
                            @change="handleShotCodeStyle"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.mail') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_contact.mail"
                            :config.sync="configForm.configFieldMail"
                            @blur="handleValidateField(configForm.configFieldMail)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.detail') }} </label>
                    <div class="col-sm-9">
                        <Editor
                            v-if="isVisibleEditor"
                            :id="`editorStyleContact`"
                            :isBackgroundImageEditor="false"
                            :model.sync="dataForm.block_contact.html_content"
                            :modelEvents="`change keydown blur focus paste`"
                            :configInit="configEditor"
                            @init="onEditorInit"
                        />
                    </div>
                </div>
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_CONTACT } from '../../styles';
import { tranferShortCode } from '@/utils/format';

import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';
import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'SettingContact',
    components: {
        InputText,
        InputCheckBox,
        Editor,
        ExportLandingPage,
        UploadImageCommon,
        Multiselect
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            isVisibleEditor: false,
            isPreview: false,
            objEditor: null,
            configEditor: {
                height: 600,
                content_style: ''
            },
            listStyleContact: LIST_STYLE_CONTACT
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileBackgroundImgContact(file) {
            this.dataForm.block_contact.background_image = file;
            this.handleShotCodeStyle();
        },

        handleDeleteTitleImage() {
            this.dataForm.block_contact.background_image = null;
            this.handleShotCodeStyle();
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        },

        handleShotCodeStyle(type = null) {
            let { background_image, background_color, is_repeat, style, title_block, contact_secretariat_name, tel } = this.dataForm.block_contact;
            if (!style) return;
            let addProper = '';
            let contentShotCode = '';
            if (type === 'select') {
                this.dataForm.block_contact.html_content = '';
                this.objEditor.setContent('');
                this.updateEditorStyle(this.objEditor, style?.cssStyle);
                this.configEditor.content_style = style?.cssStyle;
            }
            let { contents, srcBgr, imgSection } = this.dataForm.block_contact.style;
            const replacements = {
                titleBlock: title_block ?? '',
                お問い合わせ事務局名: contact_secretariat_name ?? '',
                sourceBgr: srcBgr ?? '',
                imgSection: imgSection ?? '',
                TEL: tel ?? ''
            };
            if (!this.dataForm.block_contact?.html_content) {
                contentShotCode = tranferShortCode(contents, replacements);
                addProper = `<div id="contactBlock" class="block__contact" style="background-color: ${background_color ?? ''};
                                                                background-image: url(${background_image?.path ?? ''});
                                                                background-repeat: ${is_repeat ? 'repeat' : 'no-repeat'};
                                                                min-height: 300px;overflow: hidden;
                                                                background-blend-mode: multiply;">
                                ${contentShotCode}
                            </div>`;
                this.objEditor.setContent(addProper ?? '');
                this.dataForm.block_contact.html_content = addProper;
            } else {
                const resultContent = this.transferHTMLToShortcode(this.dataForm.block_contact.html_content);
                contentShotCode = tranferShortCode(resultContent, replacements);
                this.objEditor.setContent(contentShotCode ?? '');
                this.dataForm.block_contact.html_content = contentShotCode;
            }
        },

        updateEditorStyle(editor, newCss) {
            const head = editor.contentDocument.head || editor.getDoc().head;
            let styleTag = head.querySelector('#dynamic-editor-style');
            if (!styleTag) {
                styleTag = document.createElement('style');
                styleTag.id = 'dynamic-editor-style';
                head.appendChild(styleTag);
            }
            styleTag.innerHTML = newCss;
        },

        transferHTMLToShortcode(html) {
            let { background_image, background_color, is_repeat } = this.dataForm.block_contact;
            const div = document.createElement('div');
            div.innerHTML = html;
            const placeholders = {
                shotTitle: '《titleBlock》',
                shotCompany: '《お問い合わせ事務局名》',
                shotPhone: '《TEL》'
                // shotTime: ''
            };

            // Thay thế background-image nếu có
            const sourceBgrElement = div.querySelector('#sourceBgr');
            if (sourceBgrElement) {
                let style = sourceBgrElement.getAttribute('style');
                style = style.replace(/url\((.*?)\)/, `url(《sourceBgr》)`);
                sourceBgrElement.setAttribute('style', style);
            }
            const sourceImgElement = div.querySelector('#sourceImg');
            if (sourceImgElement) {
                sourceImgElement.src = `《imgSection》`;
            }
            const contactBlockElement = div.querySelector('#contactBlock');
            if (contactBlockElement) {
                contactBlockElement.style.backgroundColor = background_color;
                contactBlockElement.style.backgroundImage = `url(${background_image?.path ?? ''})`;
                contactBlockElement.style.backgroundRepeat = is_repeat ? 'repeat' : 'no-repeat';
            }

            // Thay thế nội dung các phần tử theo id
            Object.keys(placeholders).forEach((id) => {
                const element = div.querySelector(`#${id}`);
                if (element) {
                    let imgHTML = '';
                    // Giữ lại thẻ img nếu có
                    element.querySelectorAll('img').forEach((img) => {
                        imgHTML += img.outerHTML;
                    });
                    // Gán nội dung mới
                    element.innerHTML = imgHTML + placeholders[id];
                }
            });

            // Trả về HTML sau khi thay thế
            return div.innerHTML;
        }
    },
    mounted() {
        setTimeout(() => {
            let { style } = this.dataForm.block_contact;
            this.isVisibleEditor = true;
            this.configEditor.content_style = style?.cssStyle;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
